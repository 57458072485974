import React from 'react';
import {LoadingBar} from '@fupa/fupa-uikit';
import { Backdrop } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

const StyledLoadingBar = styled(LoadingBar)`
  &&& {
    position: relative;
  }
`;

const useStyles = makeStyles({
  root: {
    zIndex: 6000000,
    backgroundColor: 'rgba(256, 256, 256, 0.6)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'flex-start',
  },
});

const LoadingOverlay = ({open}) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.root} open={open}>
      <StyledLoadingBar />
    </Backdrop>
  );
};

export {LoadingOverlay};
